var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("the-wrapper-wallet", {
    scopedSlots: _vm._u([
      {
        key: "leftColItem1",
        fn: function () {
          return [_c("module-nft-manager")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }